import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import PageContainer from '../../components/PageContainer'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Compliance = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Legal', href: '/legal', active: false },
    { label: 'Compliance Statement', href: '#', active: true }
  ];



  return (
    <React.Fragment>
            <Helmet>
                <title>Compliance Statement | Barter Funds</title>
                <meta name="description" content="Read Barter Funds' Compliance Statement detailing our adherence to Ghana's financial laws, AML/CFT regulations, and international compliance standards. Ensuring a secure and trusted platform for all users." />
                <meta name="keywords" content="Compliance Statement, Financial Compliance, AML/CFT Regulations, Ghana Laws, Barter Funds, Regulatory Adherence, Secure Platform" />
                <meta name="author" content="Barter Funds" />
                <meta property="og:title" content="Affiliate Policy | Barter Funds" />
                <meta property="og:description" content="Detailed information about Barter Funds' Affiliate Policy, including how to join and the benefits of being an affiliate." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barterfunds.com/affiliate-policy" />
                <meta property="og:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta property="og:site_name" content="Barter Funds" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Affiliate Policy | Barter Funds" />
                <meta name="twitter:description" content="Learn more about the Terms of Service of Barter Funds' affiliate program." />
                <meta name="twitter:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta name="twitter:site" content="@BarterFunds" />
            </Helmet>
      <Header />
      <Head title="Compliance Statement | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h1" className="fw-normal">
            Compliance Statement
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer bodyClass="page-container">
            <article className="entry">
      <p>
        Upholding the highest standards of security, transparency, and
        regulatory adherence.
      </p>



        <h1 className="sub-title">Regulatory Compliance</h1>
        <p>
          At Barter Funds, we adhere to all applicable local and international
          laws, regulations, and industry standards. Our compliance framework
          ensures full alignment with Anti-Money Laundering (AML) and
          Combating the Financing of Terrorism (CFT) requirements, providing a
          secure platform for all users.
        </p>

        <h1 className="sub-title">Customer Due Diligence</h1>
        <p>
          We implement robust Know Your Customer (KYC) processes to verify the
          identity of our users. This ensures that only legitimate individuals
          and entities can access our platform, protecting the community from
          fraudulent or illegal activities.
        </p>
    

        <h1 className="sub-title">Anti-Money Laundering (AML)</h1>
        <p>Our AML policy includes:</p>
        <p>
        <ul className="bullet-list">
          <li>Monitoring transactions for unusual or illegal activity.</li>
          <li>Reporting suspicious activities to relevant authorities.</li>
          <li>
            Regularly updating our policies and training staff to meet
            evolving regulatory standards.
          </li>
        </ul>
        </p>


        <h1 className="sub-title">Data Protection and Privacy</h1>
        <p>
          We comply with data protection regulations such as GDPR and
          implement advanced encryption technologies to safeguard your data.
          Your privacy is our priority.
        </p>


        <h1 className="sub-title">Continuous Improvement</h1>
        <p>
          We routinely review and update our compliance policies to reflect
          the latest regulatory changes and industry best practices.
        </p>


        <h1 className="sub-title">Your Role in Compliance</h1>
        <p>
          By providing accurate information and adhering to our terms and
          conditions, you contribute to a safe and compliant environment for
          everyone.
        </p>

      <footer className="footer">
        <p>
          For more details, refer to our{" "}
          <a href="/terms-of-service" className="link">
            Terms and Conditions
          </a>{" "}
          or{" "}
          <a href="/aml-policy" className="link">
            AML Policy
          </a>
          .
        </p>
      </footer>
 
  
            
            
            </article>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Compliance;
