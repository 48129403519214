import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import PageContainer from '../../components/PageContainer'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const KYCPolicy = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Legal', href: '/legal', active: false },
    { label: 'KYC Policy', href: '#', active: true }
  ];

  return (
    <React.Fragment>
            <Helmet>
                <title>KYC Policy | Barter Funds</title>
                <meta name="description" content="Learn about Barter Funds' KYC Policy, designed to ensure compliance with Ghana's AML laws and global best practices. We prioritize secure identity verification, customer protection, and anti-money laundering measures." />
                <meta name="keywords" content="KYC Policy, Know Your Customer, Anti-Money Laundering, AML, Barter Funds, Ghana KYC Laws, Customer Verification, Financial Compliance" />
                <meta name="author" content="Barter Funds" />
                <meta property="og:title" content="Affiliate Policy | Barter Funds" />
                <meta property="og:description" content="Detailed information about Barter Funds' Affiliate Policy, including how to join and the benefits of being an affiliate." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barterfunds.com/affiliate-policy" />
                <meta property="og:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta property="og:site_name" content="Barter Funds" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Affiliate Policy | Barter Funds" />
                <meta name="twitter:description" content="Learn more about the Terms of Service of Barter Funds' affiliate program." />
                <meta name="twitter:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta name="twitter:site" content="@BarterFunds" />
            </Helmet>
      <Header />
      <Head title="KYC Policy | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h1" className="fw-normal">
            KYC Policy
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer bodyClass="page-container">
            <article className="entry">
            <div>
      <p>
        At <strong>Barter Funds</strong>, we are committed to adhering to the laws and regulations of Ghana, including
        the Anti-Money Laundering Act, 2008 (Act 749), as amended, and guidelines issued by the Financial Intelligence
        Centre (FIC). Our Know Your Customer (KYC) policy aims to prevent identity theft, financial fraud, money
        laundering, and the financing of terrorism.
      </p>

      <h1 className='sub-title'>Objectives of Our KYC Policy</h1>
      <ul className='bullet-list'>
        <li>Verify the identity of our customers to ensure compliance with regulatory requirements.</li>
        <li>Understand the nature of the customer's activities to assess and manage risks effectively.</li>
        <li>Ensure that Barter Funds is not used for illegal purposes such as money laundering or fraud.</li>
        <li>Protect the interests of our customers and the integrity of our platform.</li>
      </ul>
<hr>
</hr>
      <h1 className='sub-title'>KYC Requirements</h1>
      <p>Customers are required to provide the following information during account registration and verification:</p>
      <ul className='bullet-list'>
        <li>
          <strong>Individual Customers:</strong>
          <ul className='bullet-list'>
            <li>Full Name</li>
            <li>Date of Birth</li>
            <li>Valid Government-Issued ID (e.g., Ghana Card, Passport, or Driver’s License)</li>
            <li>Proof of Address (e.g., utility bill, bank statements, tenancy agreement)</li>
            <li>Phone Number and Email Address</li>
          </ul>
        </li>
        <li>
          <strong>Corporate Entities:</strong>
          <ul className='bullet-list'>
            <li>Company Registration Certificate</li>
            <li>Tax Identification Number (TIN)</li>
            <li>Details of Directors and Beneficial Owners</li>
            <li>Proof of Company Address</li>
            <li>Board Resolution Authorizing Transactions</li>
          </ul>
        </li>
      </ul>
<hr>
</hr>
      <h1 className='sub-title'>Verification Process</h1>
      <p>
        Our verification process includes cross-checking the submitted documents against national databases and other
        reliable sources. Customers will not be able to perform transactions on our platform until verification is
        complete.
      </p>

      <h1 className='sub-title'>Customer Responsibilities</h1>
      <ul className='bullet-list'>
        <li>Provide accurate and up-to-date information during registration.</li>
        <li>Update Barter Funds in case of any changes to personal or business details.</li>
        <li>Cooperate with Barter Funds during the KYC review process.</li>
      </ul>
<hr>
</hr>
      <h1 className='sub-title'>Data Protection and Privacy</h1>
      <p>
        Barter Funds complies with Ghana's Data Protection Act, 2012 (Act 843), ensuring that all customer information
        collected during the KYC process is securely stored and used solely for compliance and operational purposes.
      </p>

      <h1 className='sub-title'>Non-Compliance and Restrictions</h1>
      <p>
        Failure to comply with the KYC requirements may result in account suspension, transaction limits, or termination
        of services. This is to ensure a safe and secure environment for all users.
      </p>

      <h1 className='sub-title'>Updates to the Policy</h1>
      <p>
        Barter Funds reserves the right to update this KYC Policy in compliance with changes in laws, regulations, or
        operational requirements. Customers will be notified of any significant changes.
      </p>

      <h1 className='sub-title'>Contact Us</h1>
      <p>
        If you have any questions or need assistance with the KYC process, please contact our customer support team at{" "}
        <a href="mailto:support@barterfunds.com">support@barterfunds.com</a>.
      </p>
    </div>
            
            </article>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default KYCPolicy;
