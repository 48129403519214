import React, { useEffect, useState } from 'react';
import axios from 'axios';
import KnowledgeBaseGrid from './KnowledgeBaseGrid';

const KnowledgeBase = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const wordpressBaseUrl = 'https://blog.barterfunds.com/wp-json/wp/v2/posts?_embed';

  useEffect(() => {
    // Fetch categories
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${wordpressBaseUrl}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    // Fetch posts
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${wordpressBaseUrl}/posts`);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchCategories();
    fetchPosts();
    setLoading(false);
  }, []);

  if (loading) return <p>Loading Knowledge Base...</p>;

  return (
    <div style={{ padding: '20px' }}>
      <header style={{ textAlign: 'center', marginBottom: '40px' }}>
        <h1>Knowledge Base</h1>
        <p>Find answers to common questions, tutorials, and guides to make the most of Barter Funds.</p>
        <input
          type="text"
          placeholder="Search articles..."
          style={{
            padding: '10px',
            width: '100%',
            maxWidth: '400px',
            marginTop: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        />
      </header>

      {/* Categories */}
      <section style={{ marginBottom: '40px' }}>
        <h2>Categories</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          {categories.map((category) => (
            <div
              key={category.id}
              style={{
                flex: '1 1 calc(25% - 20px)',
                padding: '20px',
                border: '1px solid #ddd',
                borderRadius: '5px',
              }}
            >
              <h2>{category.name}</h2>
              <p>{category.description || 'Browse articles in this category.'}</p>
              <a
                href={`/knowledge-base/category/${category.slug}`}
                style={{ color: '#800020', textDecoration: 'none' }}
              >
                View Articles
              </a>
            </div>
          ))}
        </div>
      </section>

      {/* Popular Posts */}
     <KnowledgeBaseGrid />


      <footer style={{ textAlign: 'center' }}>
        <p>
          Still need help? <a href="/support" style={{ color: '#800020' }}>Contact Support</a>
        </p>
      </footer>
    </div>
  );
};

export default KnowledgeBase;
