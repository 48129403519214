import React from 'react';
import { Helmet } from 'react-helmet';

import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Company = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Company', href: '#', active: true }
  ];

  return (
    <React.Fragment>

      <Helmet>
        <title>Company | Barter Funds</title>
        <meta name="description" content="Get in touch with Barter Funds for any questions, support, or business inquiries. Our team is here to help you with your digital financial needs. Reach out via live chat, email, or through our social media channels." />
        <meta name="keywords" content="Contact Barter Funds, customer support, business inquiries, help, digital financial services, e-currency exchange" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Barter Funds" />
        <meta property="og:title" content="Contact Us | Barter Funds" />
        <meta property="og:description" content="Need assistance? Contact Barter Funds' support team for help with your account, transactions, or any other inquiries. We're here to assist you with your digital financial needs." />
      </Helmet>

      <Header />
      <Head title="Company | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h1" className="fw-normal">
              Company
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          
          <div className="company-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h3>Welcome to Barter Funds</h3>
          <p>
            Your trusted partner for secure, fast, and seamless digital currency transactions.
            Join thousands of users reshaping the future of finance.
          </p>
          <a href="/signup" className="hero-button">Get Started</a>
        </div>
        <div className="hero-image">
          <img src="./assets/company-hero.jpg" alt="Barter Funds Hero" />
        </div>
      </section>

      {/* Who We Are */}
      <section className="about-section">
        <h3>Who We Are</h3>
        <p>
          Barter Funds is a leading digital currency exchange platform dedicated to helping individuals
          and businesses manage their digital assets with ease. With a commitment to innovation,
          security, and inclusivity, we aim to make digital finance accessible to everyone.
        </p>
        <div className="about-image">
          <img src="./assets/company.jpg" alt="Who We Are" />
        </div>
      </section>

      {/* How We Work */}
      <section className="how-we-work">
        <h3>How We Work</h3>
        <div className="work-steps">
          <div className="step">
            <img src="./assets/wine.jpg" alt="Step 1" />
            <h4>1. Sign Up</h4>
            <p>Create an account in minutes with our user-friendly interface.</p>
          </div>
          <div className="step">
            <img src="./assets/wine.jpg" alt="Step 2" />
            <h4>2. Choose Your Service</h4>
            <p>Buy, sell, send, or receive digital assets—all in one platform.</p>
          </div>
          <div className="step">
            <img src="./assets/wine.jpg" alt="Step 3" />
            <h4>3. Complete Your Transactions</h4>
            <p>Enjoy fast, secure, and seamless transactions backed by advanced technology.</p>
          </div>
        </div>
      </section>

      {/* Our Impact */}
      <section className="impact-section">
        <h3>Our Impact</h3>
        <div className="impact-stats">
          <div className="stat">
            <h4>5,000+</h4>
            <p>Active users globally.</p>
          </div>
          <div className="stat">
            <h4>9,000+</h4>
            <p>Transactions processed securely.</p>
          </div>
          <div className="stat">
            <h4>99%</h4>
            <p>Customer satisfaction rate.</p>
          </div>
        </div>
      </section>

      {/* Our Resources */}
      <section className="resources-section">
        <h3>Our Resources</h3>
        <div className="resources">
          <div className="resource-item">
            <h3>Knowledge Base</h3>
            <p>
              Access guides, tutorials, and insights to navigate the world of digital currencies.
            </p>
          </div>
          <div className="resource-item">
            <h3>Customer Support</h3>
            <p>
              Our 24/7 support team is ready to assist you with any questions or concerns.
            </p>
          </div>
          <div className="resource-item">
            <h3>Security Tools</h3>
            <p>
              Discover our robust security measures, including two-factor authentication and encryption.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Join the Barter Funds Family</h2>
        <p>
          Ready to take control of your digital finances? Sign up today and discover a world
          of possibilities.
        </p>
        <a href="/signup" className="cta-button">Create an Account</a>
      </section>
    </div>
 
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Company;
