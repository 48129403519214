import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const KnowledgeBaseGrid = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "https://blog.barterfunds.com/wp-json/wp/v2/posts?_embed"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <section style={{ marginBottom: "40px" }}>
      <h2>Popular Articles</h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
          gap: "20px",
        }}
      >
        {posts.map((post) => (
          <div
            key={post.id}
            style={{
              border: "1px solid #ddd",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            {post._embedded && post._embedded["wp:featuredmedia"] && (
              <img
                src={post._embedded["wp:featuredmedia"][0].source_url}
                alt={post.title.rendered}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                }}
              />
            )}
            <div style={{ padding: "20px" }}>
              <h3 style={{ margin: "0 0 10px", fontSize: "18px" }}>
                <Link
                  to={`/knowledge-base/${post.slug}`}
                  style={{ textDecoration: "none", color: "#800020" }}
                >
                  {post.title.rendered}
                </Link>
              </h3>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {post.excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "")}
              </p>
              <Link
                to={`/knowledge-base/${post.slug}`}
                style={{
                  color: "#800020",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KnowledgeBaseGrid;
