import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import openNotification from "../components/OpenNotification";
import { useNavigate } from 'react-router-dom';
import Loader from "../components/Loader";

const TwoFactor = () => {
  const navigate = useNavigate();
  const inputsRef = useRef(null);

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [imageSrc, setImageSrc] = useState(null);
  const [authCode, setAuthCode] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(true); 


  useEffect(() => {
    document.title = "Two Factor Authentication | BarterFunds";
    setIsLoading(true);

    const token = window.sessionStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/two-factor-auth`, { headers })
      .then((response) => {
        if (response.data.success) {
          setImageSrc(response.data.user.twoFactorAuthQrcode);
          setTwoFactorAuth(response.data.user.twoFactorAuth);
          setAuthCode(response.data.user.twoFactorAuthSecretKey)
          setFirstTimeLogin(response.data.firstTime)
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  }, []);

  useEffect(() => {
    // Perform any actions you want when inputValues change
  }, [inputValues]);


  const handleInputChange = (index, value) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    if (sanitizedValue.length > 1) return; // Prevent multi-character input
  
    const newInputValues = [...inputValues];
    newInputValues[index] = sanitizedValue;
    setInputValues(newInputValues);
  
    // Enable submit button if all fields are filled
    const allFieldsFilled = newInputValues.every((val) => val.length === 1);
    setSubmitButton(allFieldsFilled);
  
    if (sanitizedValue && index < 5) {
      focusNextInput(index + 1); // Automatically move to the next input
    }
  };

  
  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      handleInputChange(index, "");
      focusPrevInput(index - 1);
    }
  };

  
// const handlePaste = (event) => {
//   event.preventDefault();
//   const pasteData = event.clipboardData.getData("text").trim();

//   // Validate that the pasted data contains exactly 6 digits
//   if (/^\d{6}$/.test(pasteData)) {
//     const sanitizedValues = pasteData.split("");

//     // Update the inputValues state
//     setInputValues(sanitizedValues);

//     // Enable the submit button as all fields are now filled
//     setSubmitButton(true);

//     // Update the value of each input field directly
//     const inputs = inputsRef.current.querySelectorAll(".input-section input");
//     sanitizedValues.forEach((char, index) => {
//       if (inputs[index]) {
//         inputs[index].value = char; // Reflect pasted values
//       }
//     });

//     // Focus the last input field
//     if (inputs[sanitizedValues.length - 1]) {
//       inputs[sanitizedValues.length - 1].focus();
//     }
//   } else {
//     openNotification(
//       "topRight",
//       "error",
//       "Invalid Input",
//       "Please paste a valid 6-digit OTP."
//     );
//   }
// };

const handlePaste = (event) => {
  event.preventDefault();
  const pasteData = event.clipboardData.getData("text").replace(/[^0-9]/g, '');
  if (pasteData.length === 6) {
    setInputValues(pasteData.split(''));
    setSubmitButton(true);

    // Update UI
    const inputs = inputsRef.current.querySelectorAll("input");
    pasteData.split('').forEach((char, idx) => {
      inputs[idx].value = char;
    });

    inputs[5].focus(); // Focus the last input field
  } else {
    openNotification(
      "topRight",
      "error",
      "Invalid Input",
      "Please paste a valid 6-digit OTP."
    );
  }
};


  const focusNextInput = (index) => {
    const inputs = inputsRef.current.querySelectorAll(".input-section input");
    if (index < inputs.length) {
      inputs[index].focus();
    }
  };

  const focusPrevInput = (index) => {
    const inputs = inputsRef.current.querySelectorAll(".input-section input");
    if (index >= 0) {
      inputs[index].focus();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const otp = inputValues.join("");

    const token = window.sessionStorage.getItem("token");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-otp/${otp}`, { headers })
      .then((response) => {
        if (response.data.success) {
          setIsLoading(false);
          openNotification('topRight', 'success', 'Success', 'Verification Successful');

          setTimeout(() => {
            navigate(`/user/dashboard`);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        openNotification('topRight', 'error', 'Invalid OTP', error.response.data.message);

        setInputValues(["", "", "", "", "", ""]);
        setSubmitButton(false);
      });
  };

  const copyText = () => {

    var text = document.getElementById("usdt-address-input");
    text.select();
 
    navigator.clipboard.writeText(text.value);
    openNotification(
      "topRight",
      "success",
      "Code copied successfully",
      ""
    );
}

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          {isLoading ? (<Loader />) : (
             <div className="nk-content">
             <div className="nk-block nk-block-middle nk-auth-body wide-xs">
               <div className="card card-bordered">
                 <div className="card-inner card-inner-lg">
                   <div className="nk-block-head">
                     <div className="nk-block-head-content">
                       <div className="nk-block-des" style={{ marginBottom: "-5%", textAlign: "center" }}>
                         {twoFactorAuth ? (
                           <p>
                             Enter the 6-digit verification code generated by your authenticator app
                           </p>
                         ) : (
                           <p style={{
                            fontSize: "20px",
                            fontWeight: "bold"
                           }}>
                              Authenticator
                           </p>
                         )}
                       </div>
                     </div>
                   </div>
 
                   
                     <div>
                       <div className="card mt-0">
                       {firstTimeLogin && (
                        <>
                        <img className="card-image" src={imageSrc} alt="QR Code" />
                        <div className="buysell-field form-group">

                             <div className="currency-box">
                               <input
                                 type="text"
                                 className="form-control form-control-lg form-control-number usdt-address-input"
                                 id="usdt-address-input"
                                 name="usdt-address-input"
                                 placeholder="1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71"
                                 value={authCode}
                                 onClick={copyText}
                                 disabled
                               />
                               <span className="currency-symbol" />
                               <div className="buysell-field form-group">
                                 <img
                                   src="/assets/images/copy-icon.svg"
                                   alt="Copy Icon"
                                   className="currency-image"
                                   id="uploaded-image"
                                   title="Click to copy code"
                                   onClick={copyText}
                                   style={{
                                     cursor: "pointer"
                                   }}
                                 />
                               </div>
                             </div>
                           </div>

                        <div>
                         <ul className="space-y-5 text-xl" style={{
                           fontSize: "15px",
                           fontWeight: "500"
                         }}>
                           <li className="mb-3">
                           <p className="">
                          1. Install Google Authenticator from the{" "}
                          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank" rel="noreferrer">
                          Play Store
                          </a> or {" "}
                           <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
                           App Store
                           </a>
                          .
                        </p>
                           </li>

                           <li className="mb-3">
                           <p className="">
                          2. Scan the QR code or copy the key displayed on this screen.
                        </p>
                           </li>


                           <li className="mb-3">
                           <p className="">
                          3. Open the authenticator app and add a new account for Barter Funds.
                        </p>
                           </li>

                           <li className="mb-3">
                           <p className="">
                          4. Enter the code generated by the authenticator app below.
                        </p>
                           </li>
                         </ul>
                         
                         </div>   
                         </>
                       )}
                        
                      <div>
                       <div>
                         <div
                           className="input-container mb-4"
                           ref={inputsRef}
                           style={{
                             display: "flex",
                             justifyContent: "space-between",
                             textAlign: "center"
                           }}
                         >
                           {inputValues.map((value, index) => (
                             <div
                               className="input-section"
                               key={index}
                               style={{ flex: 1, marginRight: "10px" }}
                             >
                               <input
                                 type="text"
                                 maxLength="1"
                                 placeholder="x"
                                 autoFocus={index === 0 ? true : false}
                                 autoComplete={index === 0 ? "one-time-code" : "off"}
                                 value={value}
                                 onChange={(e) => handleInputChange(index, e.target.value)}
                                 onKeyDown={(e) => handleKeyDown(e, index)}
                                 onPaste={index === 0 ? handlePaste : undefined}
                                 style={{
                                   width: "100%",
                                   padding: "10px",
                                   border: "1px solid #ccc",
                                   borderRadius: "5px",
                                   fontSize: "16px",
                                   textAlign: "center",
                                   marginTop: "20px",
                                 }}
                               />
                             </div>
                           ))}
                         </div>
                       </div>
 
                       <button
                         onClick={handleSubmit}
                         className="btn btn-lg btn-primary btn-block"
                         disabled={!submitButton}
                       >
                         Submit
                       </button>
                     </div>

                         
                       </div>
 
                     </div>
                  
 
                   <div className="form-group mt-3"></div>
                 </div>
               </div>
             </div>
 
             <div>
               <p className="card-description nk-block-des mb-5 mt-2 text-center">
                 Need help signing in?
                 <a href="/support-center"
                   style={{
                     color: "#810020",
                     cursor: "pointer",
                     marginLeft: "3px"
                   }}
                 >
                   Contact Us
                 </a>
               </p>
             </div>
           </div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
