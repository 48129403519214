import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(
          `https://blog.barterfunds.com/wp-json/wp/v2/posts?slug=${slug}&_embed`
        );
        setArticle(response.data[0]); // Slug is unique, so we get the first result
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticle();
  }, [slug]);

  if (!article) return <p>Loading...</p>;

  return (
    <div style={{ padding: "20px" }}>
      <h1>{article.title.rendered}</h1>
      {article._embedded && article._embedded["wp:featuredmedia"] && (
        <img
          src={article._embedded["wp:featuredmedia"][0].source_url}
          alt={article.title.rendered}
          style={{
            width: "100%",
            maxHeight: "400px",
            objectFit: "cover",
            marginBottom: "20px",
          }}
        />
      )}
      <div
        dangerouslySetInnerHTML={{ __html: article.content.rendered }}
        style={{ lineHeight: "1.8", fontSize: "16px", color: "#333" }}
      />
    </div>
  );
};

export default ArticleDetail;
