import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import PageContainer from '../../components/PageContainer'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Disclaimer = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Legal', href: '/legal', active: false },
    { label: 'Disclaimer', href: '#', active: true }
  ];

  
  

 

  return (
    <React.Fragment>
            <Helmet>
                <title>Legal Disclaimer | Barter Funds</title>
                <meta name="description" content="Read Barter Funds' Compliance Statement detailing our adherence to Ghana's financial laws, AML/CFT regulations, and international compliance standards. Ensuring a secure and trusted platform for all users." />
                <meta name="keywords" content="Compliance Statement, Financial Compliance, AML/CFT Regulations, Ghana Laws, Barter Funds, Regulatory Adherence, Secure Platform" />
                <meta name="author" content="Barter Funds" />
                <meta property="og:title" content="Affiliate Policy | Barter Funds" />
                <meta property="og:description" content="Detailed information about Barter Funds' Affiliate Policy, including how to join and the benefits of being an affiliate." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barterfunds.com/affiliate-policy" />
                <meta property="og:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta property="og:site_name" content="Barter Funds" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Affiliate Policy | Barter Funds" />
                <meta name="twitter:description" content="Learn more about the Terms of Service of Barter Funds' affiliate program." />
                <meta name="twitter:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta name="twitter:site" content="@BarterFunds" />
            </Helmet>
      <Header />
      <Head title="Legal Disclaimer | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h1" className="fw-normal">
            Legal Disclaimer
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer bodyClass="page-container">
            <article className="entry">
  

            
      <h1 className="sub-title">1. No Financial Advice</h1>
      <p className="text">
        barterfunds.com provides a platform for buying, selling, exchanging, and managing
        digital currencies. The information on this website, including tutorials, tools, or
        resources, is for informational purposes only and does not constitute financial,
        investment, or legal advice. We recommend consulting a qualified professional for
        specific advice tailored to your needs.
      </p>
    

    
      <h1 className="sub-title">2. No Guarantee of Service Continuity</h1>
      <p className="text">
        While we strive to provide uninterrupted service, barterfunds.com does not guarantee
        the continuous availability of our platform or services. System failures, maintenance,
        or unforeseen issues may occur, and we are not liable for any loss resulting from such
        interruptions.
      </p>
    

    
      <h1 className="sub-title">3. Third-Party Services</h1>
      <p className="text">
        Our platform may interact with third-party services, such as payment processors, digital
        wallet providers, or cryptocurrency networks. barterfunds.com is not responsible for
        the actions, errors, or security measures of these third parties.
      </p>
    

    
      <h1 className="sub-title">4. Risk of Digital Currency Transactions</h1>
      <p className="text">
        Digital currency transactions are inherently risky due to market volatility,
        technological vulnerabilities, and potential regulatory changes. Users must perform
        their due diligence and understand the risks before engaging in transactions.
        barterfunds.com shall not be held liable for losses arising from price fluctuations,
        technical issues, or unauthorized access.
      </p>
    

    
      <h1 className="sub-title">5. Compliance with Laws</h1>
      <p className="text">
        Users of barterfunds.com are responsible for ensuring their use of the platform
        complies with all applicable laws and regulations in their jurisdiction.
        barterfunds.com does not guarantee compliance with local laws and shall not be held
        liable for unlawful use of the platform.
      </p>
    
    
      <h1 className="sub-title">6. Limitation of Liability</h1>
      <p className="text">
        To the maximum extent permitted by law, barterfunds.com, its employees, affiliates, and
        partners shall not be held liable for direct, indirect, incidental, or consequential
        damages arising from the use or inability to use our platform.
      </p>
    

    
      <h1 className="sub-title">7. No Warranty</h1>
      <p className="text">
        barterfunds.com and its services are provided on an "as-is" and "as-available" basis.
        We make no representations or warranties, express or implied, regarding the accuracy,
        reliability, or suitability of the information or services provided.
      </p>
    

    
      <h1 className="sub-title">8. Indemnity</h1>
      <p className="text">
        By using our platform, you agree to indemnify and hold harmless barterfunds.com and its
        affiliates from any claims, liabilities, or expenses resulting from your breach of this
        disclaimer or misuse of the platform.
      </p>
    

    
      <h1 className="sub-title">9. Changes to the Disclaimer</h1>
      <p className="text">
        barterfunds.com reserves the right to amend this Legal Disclaimer at any time.
        Continued use of our platform constitutes acceptance of the revised terms.
      </p>
    

    <p className="text">
      If you have any questions regarding this Legal Disclaimer, please contact us at{" "}
      <a href="mailto:support@barterfunds.com" className="link">
        support@barterfunds.com
      </a>
      .
    </p>
 
 
            
            </article>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Disclaimer;
