import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import PageContainer from '../../components/PageContainer'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Legal = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Legal', href: '#', active: true }
  ];

  const legalLinks = [
      { name: 'Terms of Service', href: '/terms-of-service' },
      { name: 'Privacy Policy', href: '/privacy-policy' },
      { name: 'Cookie Policy', href: '/cookies-policy' },
      { name: 'Refund Policy', href: '/refund-policy' },
      { name: 'Payment Policy', href: '/payment-policy' },
      { name: 'Affiliate Policy', href: '/affiliate-policy' },
      { name: 'AML/CFT Policy', href: '/aml-policy' },
      { name: 'KYC Policy', href: '/kyc-policy' },
      { name: 'Compliance Statement', href: '/compliance-statement' },
      { name: 'Legal Disclaimer', href: '/legal-disclaimer' },
    ];

  return (
    <React.Fragment>
            <Helmet>
        <title>Legal Information | BarterFunds</title>
        <meta name="description" content="Explore the legal information hub for BarterFunds. Access our Terms of Service, Privacy Policy, Cookie Policy, and other legal documents." />
        <meta name="keywords" content="BarterFunds, Legal, Terms of Service, Privacy Policy, Cookie Policy, Refund Policy, Legal Disclaimer" />
        <meta name="author" content="BarterFunds" />
      </Helmet>
      <Header />
      <Head title="Legal Information | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h2" className="fw-normal">
              Legal Information
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer bodyClass="page-container">
          <div className="legal-container">
      <header className="legal-header">
        <p>Welcome to our Legal Information hub. Explore our policies and terms below:</p>
      </header>
      <div className="legal-links">
        {legalLinks.map((link, index) => (
          <a key={index} href={link.href} className="legal-link">
            {link.name}
          </a>
        ))}
      </div>
    </div>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Legal;
